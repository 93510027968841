var generic = generic || {};
var site = site || {};
var MantleMultiLang = MantleMultiLang || {};

(function ($) {
  Drupal.behaviors.countryChooserFormatterV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $countryChooserFormatter = $('.js-country-chooser-formatter-v1', context);
      var $countryChooserLink = $('.js-country-chooser-link', context);
      var $closeButton = $('.js-country-chooser-formatter-close', context);
      var cookieName = 'COUNTRY_CHOOSER';

      function hasCookieCurrentLocale() {
        var localeName = MantleMultiLang.getCurrentLocale();
        var valueCookieForCurrentLocale = 'COUNTRY_CHOOSER_' + localeName;
        var hasLocaleIntCookie = 0;

        if ($.cookie(cookieName) !== undefined && $.cookie(cookieName) !== null) {
          if ($.cookie(cookieName) === valueCookieForCurrentLocale) {
            hasLocaleIntCookie = 1;
          } else {
            hasLocaleIntCookie = 0;
          }
        }

        return hasLocaleIntCookie;
      }

      function setupCookie() {
        var localeName = MantleMultiLang.getCurrentLocale();
        var cookieValue = 'COUNTRY_CHOOSER_' + localeName;

        $.cookie(cookieName, cookieValue, {
          expires: 14,
          path: '/'
        });
      }
      function setCountryChooserBanner(currentLocale) {
        if (!currentLocale) {
          return;
        }
        var countryIP = currentLocale.country_from_ip;
        var siteLocale = currentLocale.locale.split('_');
        var isSameCountry = countryIP === siteLocale[1] ? 1 : 0;
        if (isSameCountry) {
          return;
        }
        $countryChooserFormatter.removeClass('hidden');
      }

      $closeButton.on('click', function (e) {
        e.preventDefault();
        $countryChooserFormatter.addClass('hidden');
        setupCookie();
      });

      $countryChooserLink.on('click', function (e) {
        e.preventDefault();
        setupCookie();
        window.location = this.href;
      });

      function displayLocaleInterstitial() {
        if (generic && generic.jsonrpc) {
          generic.jsonrpc.fetch({
            method: 'locale.detect',
            params: [],
            onSuccess: function (jsonRpcResponse) {
              setCountryChooserBanner(jsonRpcResponse.getValue());
            },
            onFailure: function () {
              setCountryChooserBanner();
            }
          });
        }
      }

      var hasLocaleIntCookie = hasCookieCurrentLocale();
      if (!hasLocaleIntCookie) {
        displayLocaleInterstitial();
      }
    }
  };
})(jQuery);
